import { getProfile } from "../../crud/account.crud";
import { put, takeLatest } from "redux-saga/effects";
export const actionTypes = {
  ACCOUNT_UPDATED: "ACCOUNT_UPDATED",
  ACCOUNT_UPDATED_SUCCESS: "ACCOUNT_UPDATED_SUCCESS",
};

const initialAuthState = {
  user: undefined,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_UPDATED_SUCCESS:
      const { user } = action.payload;
      return { user };
    default:
      return state;
  }
};

export const actions = {
  accountUpdated: () => ({
    type: actionTypes.ACCOUNT_UPDATED,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ACCOUNT_UPDATED, function* accountUpdated(
    action
  ) {
    const { data } = yield getProfile();
    yield put({
      type: actionTypes.ACCOUNT_UPDATED_SUCCESS,
      payload: {
        user: data ? data.data : undefined,
      },
    });
  });
}
