import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as account from "./ducks/account.duck";
import * as address from "./ducks/address.duck";
import * as serviceRequest from "./ducks/service-request.duck";
import { metronic } from "../../_metronic";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  account: account.reducer,
  address: address.reducer,
  serviceRequest: serviceRequest.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer
});

export function* rootSaga() {
  yield all([auth.saga(),account.saga(),address.saga(),serviceRequest.saga()]);
}
