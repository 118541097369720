import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import EditAddress from "./edit-address";
import RemoveAddress from "./remove-address";
import { listAddress } from "../../../crud/address.crud";
import TimeSlotOptions from "../../../constants/time-slot-options";
import DaysOfWeek from "../../../constants/days-of-week";

const AddressList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  const [showAddressEdit, setShowAddressEdit] = useState(false);

  const [selectedData, setSelectedData] = useState();

  const [showAddressDelete, setShowAddressDelete] = useState(false);

  const getDays = (daysOfWeekData) => {
    return daysOfWeekData.map((day, i) => {
      return daysOfWeekData[i + 1]
        ? `${DaysOfWeek[day - 1]}, `
        : `${DaysOfWeek[day - 1]}`;
    });
  };

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.address.listData) {
      let listObject = {
        data: [...props.data.address.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.address.listData.data.data.total,
        totalPage: Math.ceil(
          props.data.address.listData.data.data.total / tableData.limit
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.address.listData]);

  const reloadList = () => {
    listAddress({ skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        if (docs.data.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(docs.data.data.total / tableData.limit),
          });
        }
      }
    );
  };

  const handleChange = (_event, value) => {
    // console.log("value", value);
    setPage(value);

    listAddress({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      if (docs.data.data) {
        let listObject = {
          data: [...docs.data.data.data],
          skip: Number((value - 1) * tableData.limit),
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(docs.data.data.total / tableData.limit),
        };
        //   console.log("listObject", listObject);
        setTableData({
          ...tableData,
          ...listObject,
        });
      }
    });
  };

  const handleCloseEdit = () => {
    setShowAddressEdit(false);
  };

  const handleCloseDelete = () => {
    setShowAddressDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowAddressEdit(true);
  };

  const openDeleteModal = (data) => {
    setSelectedData(data);
    setShowAddressDelete(true);
  };

  return (
    <React.Fragment>
      {showAddressEdit ? (
        <EditAddress
          addressData={selectedData}
          show={showAddressEdit}
          handleClose={handleCloseEdit}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {showAddressDelete ? (
        <RemoveAddress
          address={selectedData}
          show={showAddressDelete}
          handleClose={handleCloseDelete}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table striped bordered hover style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Title</th>
                <th>Address</th>
                <th>Working Time Slots</th>
                <th>Days Of Week</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={eachData._id}>
                    <td style={{ wordWrap: "break-word" }}>{eachData._id}</td>
                    <td>{eachData.title}</td>
                    <td>{eachData.address}</td>
                    <td style={{ wordWrap: "break-word" }}>
                      {eachData.workingTimeSlots.map((ts, i) => {
                        const label = TimeSlotOptions.filter(
                          (tso) => tso.value === ts
                        )[0].label;
                        return eachData.workingTimeSlots[i + 1]
                          ? `${label}, `
                          : `${label}`;
                      })}
                    </td>
                    <td>{getDays(eachData.daysOfWeek)}</td>
                    <td>
                      <p
                        className="btn btn-sm btn-clean btn-icon btn-icon-md"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-sm btn-clean btn-icon btn-icon-md"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            fontSize: 20,
            height: "100%",
          }}
        >
          No Data Available
        </p>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressList);
