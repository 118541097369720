
const TimeSlotOptions = [{
    label: '00:00 AM', 
    value: 1
  }, 
  {
    label: '00:30 AM', 
    value: 2
  }, 
  {
    label: '01:00 AM', 
    value: 3
  }, 
  {
    label: '01:30 AM', 
    value: 4
  }, 
  {
    label: '02:00 AM', 
    value: 5
  }, 
  {
    label: '02:30 AM', 
    value: 6
  },
  {
    label: '03:00 AM', 
    value: 7
  }, 
  {
    label: '03:30 AM', 
    value: 8
  }, 
  {
    label: '04:00 AM', 
    value: 9
  }, 
  {
    label: '04:30 AM', 
    value: 10
  }, 
  {
    label: '05:00 AM', 
    value: 11
  }, 
  {
    label: '05:30 AM', 
    value: 12
  },
  {
    label: '06:00 AM', 
    value: 13
  }, 
  {
    label: '06:30 AM', 
    value: 14
  }, 
  {
    label: '07:00 AM', 
    value: 15
  }, 
  {
    label: '07:30 AM', 
    value: 16
  }, 
  {
    label: '08:00 AM', 
    value: 17
  }, 
  {
    label: '08:30 AM', 
    value: 18
  },
  {
    label: '09:00 AM', 
    value: 19
  }, 
  {
    label: '09:30 AM', 
    value: 20
  }, 
  {
    label: '10:00 AM', 
    value: 21
  },
  {
    label: '10:30 AM', 
    value: 22
  }, 
  {
    label: '11:00 AM', 
    value: 23
  }, 
  {
    label: '11:30 AM', 
    value: 24
  }, 
  {
    label: '12:00 PM', 
    value: 25
  }, 
  {
    label: '12:30 PM', 
    value: 26
  },
  {
    label: '01:00 PM', 
    value: 27
  }, 
  {
    label: '01:30 PM', 
    value: 28
  }, 
  {
    label: '02:00 PM', 
    value: 29
  }, 
  {
    label: '02:30 PM', 
    value: 30
  },
  {
    label: '03:00 PM', 
    value: 31
  }, 
  {
    label: '03:30 PM', 
    value: 32
  }, 
  {
    label: '04:00 PM', 
    value: 33
  }, 
  {
    label: '04:30 PM', 
    value: 34
  }, 
  {
    label: '05:00 PM', 
    value: 35
  }, 
  {
    label: '05:30 PM', 
    value: 36
  },
  {
    label: '06:00 PM', 
    value: 37
  }, 
  {
    label: '06:30 PM', 
    value: 38
  }, 
  {
    label: '07:00 PM', 
    value: 39
  }, 
  {
    label: '07:30 PM', 
    value: 40
  }, 
  {
    label: '08:00 PM', 
    value: 41
  }, 
  {
    label: '08:30 PM', 
    value: 42
  },
  {
    label: '09:00 PM', 
    value: 43
  }, 
  {
    label: '09:30 PM', 
    value: 44
  }, 
  {
    label: '10:00 PM', 
    value: 45
  }, 
  {
    label: '10:30 PM', 
    value: 46
  }, 
  {
    label: '11:00 AM', 
    value: 47
  }, 
  {
    label: '11:30 PM', 
    value: 48
  }];

  export default TimeSlotOptions;