/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useLocation
} from "react-router-dom";

import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import { LayoutContextConsumer } from "../LayoutContext";
import * as builder from "../../ducks/builder";
import CreateAddress from './../../../app/pages/home/address-book-management/create-address';
const SubHeader = (props)=> {
  let location = useLocation();
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses,
      subheaderMobileToggle
    } = props;
    const [showAddAddress, setShowAddAddress] = useState(false);
    const handleClose = (page)=> {
      switch(page){
        case 'address': 
          setShowAddAddress(false);
          break;
        default: 
          break;
      }
    };
    
    const handleShow = (page) => {
       console.log(page);
        switch(page){
          case 'address': 
            setShowAddAddress(true);
            break;
          default: 
            break;
        }
    };

    useEffect(()=>{
      return ()=>{
      }
    })

    return (
      <div
        id="kt_subheader"
        className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
      >
        <CreateAddress show={showAddAddress} handleClose={handleClose}/>
        
        <div className={`kt-container ${subheaderContainerCssClasses}`}>
          <div className="kt-subheader__main">
            {subheaderMobileToggle && (
              <button
                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}

            <LayoutContextConsumer>
              {({ subheader: { title } }) => (
                <>
                  <h3 className="kt-subheader__title">{title ? title: location.state? location.state.title: ''}</h3>
                </>
              )}
            </LayoutContextConsumer>
          </div>

          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              
               <LayoutContextConsumer>

              {({ subheader: { title } }) => (
                <>
                {title && title!=='Dashboard' && title!=='Service Request Management' ?  (<a
                            onClick={()=>{
                              switch(title){
                                case 'Address Management':
                                  handleShow('address');
                                  break;
                                default:
                                  break;
                              }
                              
                            }}
              className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
            >
              Add New
            </a>): null}
              
            </>
              )}
            </LayoutContextConsumer>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  })
});

export default withRouter(connect(mapStateToProps)(SubHeader));
