import React, {useEffect, useRef } from "react";

let autoComplete;
function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    // { types: ["(cities)"], componentRestrictions: { country: "us" } }
  );
  autoComplete.setFields(["address_components", "formatted_address", 'geometry']);
  autoComplete.addListener("place_changed", () =>
    {
      handlePlaceSelect(updateQuery)
    }
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const address = addressObject.formatted_address;
  const location = {
    lat : addressObject.geometry.location.lat(),
    lng : addressObject.geometry.location.lng()
  }
  updateQuery({
    address, 
    location
  });
  console.log(addressObject);
}

function GoogleSearchLocationInput(props) {
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    handleScriptLoad(props.setAddressQuery, autoCompleteRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="search-location-input">
      <input
        ref={autoCompleteRef}
        placeholder="Enter address"
        defaultValue={props.addressQuery.address}
        className={`form-control`}
      />
    </div>
  );
}

export default GoogleSearchLocationInput;