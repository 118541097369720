import {listServiceRequest} from "../../crud/service-request.crud";
import {put, takeLatest} from "redux-saga/effects";
export const actionTypes = {
    SERVICE_REQUEST_UPDATED: "SERVICE_REQUEST_UPDATED",
    SERVICE_REQUEST_UPDATED_SUCCESS: "SERVICE_REQUEST_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
       
        case actionTypes.SERVICE_REQUEST_UPDATED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        default:
            return state;
    }
};

export const actions = {
  
    vendorCategoryUpdated: ({skip, limit}) => ({
        type: actionTypes.SERVICE_REQUEST_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function * saga() {

    yield takeLatest(actionTypes.SERVICE_REQUEST_UPDATED, function * serviceRequestUpdated(action) {
        const {skip, limit} = action.payload;
        const listData = yield listServiceRequest({skip, limit});

        yield put({type: actionTypes.SERVICE_REQUEST_UPDATED_SUCCESS, payload: {
                listData
            }});
    });
}
