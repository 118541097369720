import React, { Suspense, lazy, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import ViewProfile from "./account/view-profile";
import Dashboard from "./Dashboard";
import AddressList from "./address-book-management/address-list";
import ServiceRequestList from "./service-request-management/service-request-list";
import GlobalSettings from "./global-settings/index";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import * as account from "../../store/ducks/account.duck";

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

const HomePage = (props)=> {
  useEffect(() => {
    props.accountUpdated();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/profile" component={ViewProfile} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/address-list" component={AddressList} />
        <Route path="/service-requests" component={ServiceRequestList} />
        <Route path="/global-settings" component={GlobalSettings} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
const mapStateToProps = state => {
  return {data: state};
};

const mapDispatchToProps = dispatch => {
  return {
      accountUpdated: () => dispatch(account.actions.accountUpdated())
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomePage);