import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { Form, Button } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { editProfile } from "../../../crud/account.crud";
import * as account from "../../../store/ducks/account.duck";
import ApiErrorMessage from "../../../crud/api.errorMessage";
const EditProfile = (props) => {
  const { user, intl, classes } = props;
  const [errors, setErrors] = useState({
    name: null,
    profilePicture: null,
    email: null,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [preview, setPreview] = useState(null);
  const observed = useRef(null);

  useEffect(() => {
    if (user.personalInfo) {
      setName(user.personalInfo.name);
      setEmail(user.personalInfo.email);
      if (user.personalInfo.profilePicture) {
        setPreview(user.personalInfo.profilePicture);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!selectedFile) {
      // setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onChangeHandler = (event) => {
    console.log("Image Fire---");
    let file = event.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      console.log(img.width);
      console.log(img.height);
      if (img.width <= 120 && img.height <= 120 && img.width === img.height) {
        console.log("true", true);
        setSelectedFile(file);
        setErrors({
          ...errors,
          profilePicture: null,
        });
        return true;
      }
      setErrors({
        ...errors,
        profilePicture: "Profile Picture should be 120x120px",
      });
      return false;
    };
  };

  const onChangeNameHandler = (event) => {
    setName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
    } else {
      setErrors({
        ...errors,
        name: null,
      });
    }
  };

  const onChangeEmailHandler = (event) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
    } else {
      setErrors({
        ...errors,
        email: null,
      });
    }
  };

  const resetState = () => {
    setSelectedFile(undefined);
    setErrors({ name: null, profilePicture: null });
  };

  const validForm = () => {
    let e = true;

    if (!name) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
      e = false;
    } else {
      setErrors({
        ...errors,
        name: null,
      });
    }
    return e;
  };

  const handleSubmit = () => {
    if (validForm()) {
      setLoading(true);
      const payload = {
        name,
      };
      if (selectedFile) {
        payload.profilePicture = selectedFile;
      }
      editProfile(payload)
        .then((output) => {
          if (output.data.success) {
            resetState();
            setLoading(false);
            console.log(output.data.data);
            props.accountUpdated(output.data.data);
            toast.success("Profile is successfully saved!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast.error(
              intl.formatMessage(
                {
                  id: ApiErrorMessage[error.response.data.errorCode],
                },
                { title: name }
              ),
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
  };
  return (
    <div className="tab-pane active" id="profile_personal_info" role="tabpanel">
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Personal Information
              <small>update your personal informaiton</small>
            </h3>
          </div>
        </div>
        <Form className="kt-form kt-form--label-right">
          <div className="kt-portlet__body">
            <div className="kt-section kt-section--first">
              <div className="kt-section__body">
                <div className="row">
                  <label className="col-xl-3"></label>
                  <div className="col-lg-9 col-xl-6">
                    <h3 className="kt-section__title kt-section__title-sm">
                      Customer Info:
                    </h3>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Profile Picture
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="kt-avatar kt-avatar--outline">
                      <div
                        className="kt-avatar__holder"
                        style={{
                          backgroundImage: `url(${
                            preview
                              ? preview
                              : toAbsoluteUrl(
                                  "/media/default-image/default-image.png"
                                )
                          })`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <div
                        className="kt-avatar__upload"
                        onClick={(e) => {
                          observed.current.click();
                        }}
                      >
                        <i className="fa fa-pen"></i>
                        <input
                          style={{
                            display: "none",
                          }}
                          ref={observed}
                          type="file"
                          name="profilePicture"
                          onChange={onChangeHandler}
                        />
                      </div>
                      <span
                        className="kt-avatar__cancel"
                        data-toggle="kt-tooltip"
                        title=""
                        data-original-title="Cancel avatar"
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <Form.Group controlId="organiser.name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={onChangeNameHandler}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="row">
                  <label className="col-xl-3"></label>
                  <div className="col-lg-9 col-xl-6">
                    <h3 className="kt-section__title kt-section__title-sm">
                      Contact Info:
                    </h3>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Email Address
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="la la-at"></i>
                        </span>
                      </div>
                      <input
                        readOnly={true}
                        type="text"
                        className="form-control"
                        value={email}
                        placeholder="Email"
                        aria-describedby="basic-addon1"
                        name="email"
                        onChange={onChangeEmailHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-portlet__foot">
            <div className="kt-form__actions">
              <Button
                className="btn btn-success"
                variant="primary"
                style={{
                  position: "relative",
                }}
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                Save Changes
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    accountUpdated: (data) => dispatch(account.actions.accountUpdated(data)),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(EditProfile)
  )
);
