import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import { injectIntl } from "react-intl";
import { Form, Button, Modal } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../../common/alert";
import GoogleSearchLocationInput from "../../common/GoogleSearchLocationInput";
import { connect } from "react-redux";
import { editAddress } from "../../../crud/address.crud";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import * as address from "../../../store/ducks/address.duck";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import TimeSlotOptions from "../../../constants/time-slot-options";

const EditAddress = (props) => {
  const { classes, intl, addressData } = props;
  const [loading, setLoading] = useState(false);
  const [locationNickName, setLocationNickName] = useState(addressData.title);
  const [addressQuery, setAddressQuery] = useState({
    address: "",
    location: {
      lat: "",
      lng: "",
    },
  });
  const [startTimeOptions, setStartTimeOptions] = useState([
    ...TimeSlotOptions,
  ]);
  const [selectedStartTime, setSelectedStartTime] = useState();
  const [endTimeOptions, setEndTimeOptions] = useState([...TimeSlotOptions]);
  const [selectedEndTime, setSelectedEndTime] = useState();
  const [errors, setErrors] = useState({
    locationNickName: null,
    address: null,
    selectedStartTime: null,
    selectedEndTime: null,
  });
  const [selectedDays, setSelectedDays] = useState(addressData.daysOfWeek);
  const [checkedSunday, setCheckedSunday] = useState(false);
  const [checkedMonday, setCheckedMonday] = useState(false);
  const [checkedTuesday, setCheckedTuesday] = useState(false);
  const [checkedWednesday, setCheckedWednesday] = useState(false);
  const [checkedThursday, setCheckedThursday] = useState(false);
  const [checkedFriday, setCheckedFriday] = useState(false);
  const [checkedSaturday, setCheckedSaturday] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  useEffect(() => {
    if (Object.keys(props.addressData).length) {
      console.log(props.addressData);
      let startTime = TimeSlotOptions.filter(
        (tso) => tso.value === props.addressData.workingTimeSlots[0]
      )[0];
      setSelectedStartTime(startTime);
      let endTime = TimeSlotOptions.filter(
        (tso) =>
          tso.value ===
          props.addressData.workingTimeSlots[
            props.addressData.workingTimeSlots.length - 1
          ]
      )[0];
      setSelectedEndTime(endTime);
      let mEndTimeOptions = JSON.parse(JSON.stringify(endTimeOptions));
      setEndTimeOptions(
        mEndTimeOptions.map((each) => {
          if (each.value <= startTime.value) {
            each.isdisabled = true;
          } else {
            each.isdisabled = false;
          }
          return each;
        })
      );
      selectedDays.forEach((element) => {
        if (element === 1) {
          setCheckedSunday(true);
        } else if (element === 2) {
          setCheckedMonday(true);
        } else if (element === 3) {
          setCheckedTuesday(true);
        } else if (element === 4) {
          setCheckedWednesday(true);
        } else if (element === 5) {
          setCheckedThursday(true);
        } else if (element === 6) {
          setCheckedFriday(true);
        } else if (element === 7) {
          setCheckedSaturday(true);
        }
      });
      console.log("props.addressData", props.addressData);
      setAddressQuery({
        address: props.addressData.address,
        location: {
          lng: props.addressData.location.coordinates[0],
          lat: props.addressData.location.coordinates[1],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addressData]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleSelectDays = (event) => {
    let days = [...selectedDays];
    switch (event.target.name) {
      case "sunday":
        if (event.target.checked) {
          days.push(1);
          setCheckedSunday(true);
        } else {
          days = days.filter((day) => day !== 1);
          setCheckedSunday(false);
        }
        break;
      case "monday":
        if (event.target.checked) {
          days.push(2);
          setCheckedMonday(true);
        } else {
          days = days.filter((day) => day !== 2);
          setCheckedMonday(false);
        }
        break;
      case "tuesday":
        if (event.target.checked) {
          days.push(3);
          setCheckedTuesday(true);
        } else {
          days = days.filter((day) => day !== 3);
          setCheckedTuesday(false);
        }
        break;

      case "wednesday":
        if (event.target.checked) {
          days.push(4);
          setCheckedWednesday(true);
        } else {
          days = days.filter((day) => day !== 4);
          setCheckedWednesday(false);
        }
        break;
      case "thursday":
        if (event.target.checked) {
          days.push(5);
          setCheckedThursday(true);
        } else {
          days = days.filter((day) => day !== 5);
          setCheckedThursday(false);
        }
        break;
      case "friday":
        if (event.target.checked) {
          days.push(6);
          setCheckedFriday(true);
        } else {
          days = days.filter((day) => day !== 6);
          setCheckedFriday(false);
        }
        break;
      case "saturday":
        if (event.target.checked) {
          days.push(7);
          setCheckedSaturday(true);
        } else {
          days = days.filter((day) => day !== 7);
          setCheckedSaturday(false);
        }
        break;
      default:
        break;
    }
    setSelectedDays(days);
  };

  const validateForm = () => {
    let validDays = true;
    let hasError = false;
    if (
      checkedSunday ||
      checkedMonday ||
      checkedTuesday ||
      checkedWednesday ||
      checkedThursday ||
      checkedFriday ||
      checkedSaturday
    ) {
      validDays = true;
    } else {
      setOpenAlert(true);
      validDays = false;
    }

    if (
      !locationNickName ||
      !address ||
      !selectedStartTime ||
      !selectedEndTime
    ) {
      hasError = true;
      let errorsData = {
        ...errors,
      };
      console.log(locationNickName);
      if (!locationNickName && locationNickName === "") {
        errorsData = {
          ...errorsData,
          locationNickName: "Location Nick Name is required!",
        };
      }

      if (!address && address === "") {
        errorsData = {
          ...errorsData,
          address: "Address is required!",
        };
      }

      if (!selectedStartTime) {
        errorsData = {
          ...errorsData,
          selectedStartTime: "Start Time is required!",
        };
      }

      if (!selectedEndTime) {
        errorsData = {
          ...errorsData,
          selectedEndTime: "End Time is required!",
        };
      }
      console.log("error", errorsData);
      setErrors(errorsData);
    }

    return !hasError && validDays;
  };

  const generateWorkingTimeSlots = (startTime, endTime) => {
    let timeSlots = [];
    for (let i = startTime; i <= endTime; i++) {
      timeSlots.push(i);
    }
    return timeSlots;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        title: locationNickName,
        address: addressQuery.address,
        workingTimeSlots: generateWorkingTimeSlots(
          selectedStartTime.value,
          selectedEndTime.value
        ),
        daysOfWeek: selectedDays,
        location: {
          type: "Point",
          coordinates: [88, 22],
        },
      };
      setLoading(true);
      editAddress(props.addressData._id, payload)
        .then((output) => {
          if (output.data.success) {
            props.addressUpdated({
              skip: props.listOption.skip,
              limit: props.listOption.limit,
            });
            resetState();
            setLoading(false);
            toast.success("Address is successfully updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.handleClose("address");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast.error(
              intl.formatMessage(
                {
                  id: ApiErrorMessage[error.response.data.errorCode],
                },
                { title: locationNickName }
              ),
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };

  const onChangeLocationNickNameHandler = (event) => {
    setLocationNickName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        locationNickName: "Location Nick Name is required",
      });
    } else {
      setErrors({
        ...errors,
        locationNickName: null,
      });
    }
  };

  const onChangeStartTimeHandler = (event) => {
    let mEndTimeOptions = JSON.parse(JSON.stringify(endTimeOptions));
    setEndTimeOptions(
      mEndTimeOptions.map((each) => {
        if (each.value <= event.value) {
          each.isdisabled = true;
        } else {
          each.isdisabled = false;
        }
        return each;
      })
    );
    setSelectedStartTime(event);
    setErrors({
      ...errors,
      selectedStartTime: null,
    });
  };

  const onChangeEndTimeHandler = (event) => {
    setSelectedEndTime(event);
    setErrors({
      ...errors,
      selectedEndTime: null,
    });
  };

  const resetState = () => {
    setLoading(false);
    setLocationNickName("");
    setAddressQuery({
      address: "",
      location: {
        lat: "",
        lng: "",
      },
    });
    setStartTimeOptions([...TimeSlotOptions]);
    setSelectedStartTime();
    setEndTimeOptions([...TimeSlotOptions]);
    setSelectedEndTime();
    setSelectedDays([]);
    setCheckedSunday(false);
    setCheckedMonday(false);
    setCheckedTuesday(false);
    setCheckedWednesday(false);
    setCheckedThursday(false);
    setCheckedFriday(false);
    setCheckedSaturday(false);
    setOpenAlert(false);
    setErrors({
      locationNickName: null,
      address: null,
      selectedStartTime: null,
      selectedEndTime: null,
    });
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={() => {
          resetState();
          props.handleClose("address");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="kt-form" id="addRoleForm">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-section">
                  <div className="kt-section__body">
                    <div className="row">
                      <div className="col-6">
                        <Form.Group controlId="address.title">
                          <Form.Label>Location Nick Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter location"
                            value={locationNickName}
                            onChange={onChangeLocationNickNameHandler}
                            isInvalid={!!errors.locationNickName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.locationNickName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="address.address">
                          <Form.Label>Address</Form.Label>
                          <GoogleSearchLocationInput
                            addressQuery={addressQuery}
                            setAddressQuery={setAddressQuery}
                          />
                          <div>
                            {!!errors.address && (
                              <div className={classes.error}>Required</div>
                            )}
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-6">
                        <Form.Group controlId="address.startTime">
                          <Form.Label>Start Time</Form.Label>
                          <Select
                            fullWidth
                            options={startTimeOptions}
                            value={selectedStartTime}
                            onChange={onChangeStartTimeHandler}
                            isOptionDisabled={(option) => option.isdisabled}
                          />
                          <div>
                            {!!errors.selectedStartTime && (
                              <div className={classes.error}>Required</div>
                            )}
                          </div>
                        </Form.Group>
                        <Form.Group controlId="address.endTime">
                          <Form.Label>End Time</Form.Label>
                          <Select
                            fullWidth
                            options={endTimeOptions}
                            value={selectedEndTime}
                            onChange={onChangeEndTimeHandler}
                            isOptionDisabled={(option) => option.isdisabled} // disable an option
                            styles={{
                              padding: "30px",
                            }}
                          />
                          <div>
                            {!!errors.selectedEndTime && (
                              <div className={classes.error}>Required</div>
                            )}
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <div
                      className="kt-section kt-section--last accessible-modules"
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <label
                        className="form-label"
                        style={{
                          marginBottom: "8px",
                        }}
                      >
                        Days
                      </label>
                      <div className="form-group row">
                        <div className="kt-checkbox-single col modules">
                          <label className="kt-checkbox kt-checkbox--brand">
                            <input
                              type="checkbox"
                              checked={checkedMonday}
                              name="monday"
                              onChange={handleSelectDays}
                            />
                            Monday
                            <span></span>
                          </label>
                        </div>
                        <div className="kt-checkbox-single col modules">
                          <label className="kt-checkbox kt-checkbox--brand">
                            <input
                              type="checkbox"
                              checked={checkedTuesday}
                              name="tuesday"
                              onChange={handleSelectDays}
                            />
                            Tuesday
                            <span></span>
                          </label>
                        </div>
                        <div className="kt-checkbox-single col modules">
                          <label className="kt-checkbox kt-checkbox--brand">
                            <input
                              type="checkbox"
                              checked={checkedWednesday}
                              name="wednesday"
                              onChange={handleSelectDays}
                            />
                            Wednesday
                            <span></span>
                          </label>
                        </div>
                        <div className="kt-checkbox-single col modules">
                          <label className="kt-checkbox kt-checkbox--brand">
                            <input
                              type="checkbox"
                              checked={checkedThursday}
                              name="thursday"
                              onChange={handleSelectDays}
                            />
                            Thursday
                            <span></span>
                          </label>
                        </div>
                        <div className="kt-checkbox-single col modules">
                          <label className="kt-checkbox kt-checkbox--brand">
                            <input
                              type="checkbox"
                              checked={checkedFriday}
                              name="friday"
                              onChange={handleSelectDays}
                            />
                            Friday
                            <span></span>
                          </label>
                        </div>
                        <div className="kt-checkbox-single col modules">
                          <label className="kt-checkbox kt-checkbox--brand">
                            <input
                              type="checkbox"
                              checked={checkedSaturday}
                              name="saturday"
                              onChange={handleSelectDays}
                            />
                            Saturday
                            <span></span>
                          </label>
                        </div>
                        <div className="kt-checkbox-single col modules">
                          <label className="kt-checkbox kt-checkbox--brand">
                            <input
                              type="checkbox"
                              checked={checkedSunday}
                              onChange={handleSelectDays}
                              name="sunday"
                            />
                            Sunday
                            <span></span>
                          </label>
                        </div>
                      </div>

                      <Snackbar
                        open={openAlert}
                        autoHideDuration={6000}
                        onClose={handleCloseAlert}
                      >
                        <Alert onClose={handleCloseAlert} severity="error">
                          Atleast One day is needed to be selected!
                        </Alert>
                      </Snackbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              resetState();
              props.handleClose("address");
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addressUpdated: ({ skip, limit }) =>
      dispatch(address.actions.addressUpdated({ skip, limit })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(EditAddress)
  )
);
