const ApiErrorMessage = {
  1000: "SESSION_NOT_FOUND",
  1001: "AUTH.VALIDATION.INVALID_OTP",
  1002: "AUTH.VALIDATION.OTP_TIMEOUT",
  1003: "AUTH.VALIDATION.PASSWORD_MISMATCH",
  2800: "AUTH.VALIDATION.INVALID_LOGIN",
  7000: "AUTH.VALIDATION.TITLE_ALREADY_ADDED",
  1500: "AUTH.VALIDATION.ORGANISER_EMAIL_ALREADY_EXISTS",
  1501: "AUTH.VALIDATION.ORGANISER_NOT_FOUND",
  2505: "AUTH.VALIDATION.ADDRESS_TAGGED_WITH_SERVICES",
  2506: "AUTH.VALIDATION.ADDRESS_TITLE_ALREADY_ADDEDD",
};

export default ApiErrorMessage;
