import React from 'react';
import {Formik} from "formik";
import {Form, Button} from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import {injectIntl} from "react-intl";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import {changePassword} from '../../../crud/account.crud';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {withStyles} from "@material-ui/core/styles";
import styles from "./styles";
const ChangePassword = (props) => {
    const {intl, classes} = props;
    return (

        <div className="tab-pane active" id="profile_change_password" role="tabpanel">
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">Change Password<small>change or reset your account password</small>
                        </h3>
                    </div>
                </div>
                <Formik
                    initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    retypeNewPassword: ''
                }}
                    validate={(values) => {
                    const errors = {};
                    if (!values.oldPassword) {
                        errors.oldPassword = intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                    }
                    if (!values.newPassword) {
                        errors.newPassword = intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                    }
                    if (!values.retypeNewPassword) {
                        errors.retypeNewPassword = intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                    }
                    if (values.newPassword && values.retypeNewPassword) {
                        if (values.newPassword !== values.retypeNewPassword) {
                            errors.retypeNewPassword = intl.formatMessage({id: "AUTH.VALIDATION.CONFIRM_PASSWORD_MISMATCH"});
                        }
                    }
                    return errors;
                }}
                    onSubmit={(values, {setStatus, resetForm, setSubmitting}) => {
                    changePassword(values).then(() => {
                        resetForm();
                        toast.success(intl.formatMessage({id: "AUTH.CHANGE_PASSWORD.SUCCESS"}), {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            style: {
                                fontSize: "14px",
                                fontWeight: "bold",
                                paddingLeft: "12px"
                            }
                        });
                    }).catch((error) => {
                        setSubmitting(false);
                        if (error.response) {
                            setStatus(intl.formatMessage({
                                id: ApiErrorMessage[error.response.data.errorCode]
                            }));
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }}>
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        resetForm
                    }) => (
                        <Form className="kt-form kt-form--label-right" onSubmit={handleSubmit}>

                            <div className="kt-portlet__body">
                                <div className="kt-section kt-section--first">
                                    <div className="kt-section__body">
                                        <Form.Group controlId="changePassword.currentPassword">
                                            <Form.Label>Current Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="oldPassword"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.oldPassword}
                                                isInvalid={!!Boolean(touched.oldPassword && errors.oldPassword)}/>
                                            <Form.Control.Feedback type="invalid">
                                                {touched.oldPassword && errors.oldPassword}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                        <Form.Group controlId="changePassword.newPassword">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="newPassword"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.newPassword}
                                                isInvalid={!!Boolean(touched.newPassword && errors.newPassword)}/>
                                            <Form.Control.Feedback type="invalid">
                                                {touched.newPassword && errors.newPassword}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                        <Form.Group controlId="changePassword.retypeNewPassword">
                                            <Form.Label>Retype New Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="retypeNewPassword"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.retypeNewPassword}
                                                isInvalid={!!Boolean(touched.retypeNewPassword && errors.retypeNewPassword)}/>
                                            <Form.Control.Feedback type="invalid">
                                                {touched.retypeNewPassword && errors.retypeNewPassword}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                        {status && (
                                            <div
                                                role="alert"
                                                style={{
                                                margin: '0 16px'
                                            }}
                                                className="alert alert-danger">
                                                <div className="alert-text">{status}</div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__foot">
                                <div className="kt-form__actions">
                                    <div className="row">
                                        <div className="col-lg-3 col-xl-3"></div>
                                        <div className="col-lg-9 col-xl-9">
                                            <Button
                                                className="btn btn-success"
                                                variant="primary"
                                                style={{
                                                position: "relative",
                                                margin: "0 16px"
                                            }}
                                                onClick={() => handleSubmit()}
                                                disabled={isSubmitting}
                                                type="submit">
                                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress}/>}Change Password
                                            </Button>
                                            <Button onClick={resetForm} type="reset" className="btn btn-secondary">Cancel</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default injectIntl((withStyles((theme) => ({
    ...styles(theme)
}), {withTheme: true})(ChangePassword)));